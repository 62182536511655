import {Inject, Injectable, PLATFORM_ID} from '@angular/core'
import {ToastrService} from 'ngx-toastr'
import {TranslocoService} from '@ngneat/transloco'
import {isPlatformBrowser} from '@angular/common'
import {take} from 'rxjs/operators'
import {Logger} from '../logging/logger.types'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    @Inject(TranslocoService) private localeService: TranslocoService,
    @Inject(PLATFORM_ID) private platformId: Record<string, unknown>,
    private readonly logger: Logger,
  ) {}

  unknownError(err): void {
    this.logger.error(err)
    // don't render the error on server side
    if (isPlatformBrowser(this.platformId)) {
      const title = this.localeService.translate('unknownError.title')
      const description = this.localeService.translate('unknownError.description')
      this.toastr
        .error(description, title, {
          timeOut: 10000,
          enableHtml: true,
          closeButton: true,
        })
        .onTap.subscribe(() => {
          window.location.reload()
        })
    }
  }

  handledWarning(titleKey: string, descriptionKey: string): void {
    // don't render the error on server side
    if (isPlatformBrowser(this.platformId)) {
      const title = this.localeService.translate(titleKey)
      const description = this.localeService.translate(descriptionKey)
      this.toastr
        .warning(description, title, {
          disableTimeOut: true,
          enableHtml: true,
          closeButton: false,
        })
        .onTap.subscribe(() => {
          window.location.reload()
        })
    }
  }

  signedIn() {
    if (isPlatformBrowser(this.platformId)) {
      this.localeService
        .selectTranslate('authentication.signedIn')
        .pipe(take(1))
        .subscribe(msg => this.toastr.success(msg))
    }
  }

  signedOut() {
    if (isPlatformBrowser(this.platformId)) {
      this.localeService
        .selectTranslate('authentication.signedOut')
        .pipe(take(1))
        .subscribe(msg => this.toastr.success(msg))
    }
  }
}
